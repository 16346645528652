import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p>
          Welcome to Neurithm. 
        </p>
        <a
          className="App-link"
          href="https://neurithm.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          Deploy clean slate protocol!!!
        </a>
      </header>
    </div>
  );
}

export default App;
